<template>
  <div>
    <v-row>
      <v-col
        cols="12"
        sm="12"
        md="12"
        style="display: flex; justify-content: space-between; align-items: center;"
      >
        <v-btn color="primary" class="ml-2 white--text" @click="addNew">
          <v-icon dark>mdi-plus</v-icon> añadir
        </v-btn>
      </v-col>
    </v-row>
    <v-row :justify="'center'">
      <v-col cols="12" sm="12" md="12">
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-data-table
            :headers="headers"
            :items="posContactData"
            class="elevation-1"
            :hide-default-footer="true"
          >
            <template v-slot:item.contact="{ item }">
              <v-text-field
                class="mt-4"
                v-model="editedItem.contact"
                dense
                single-line
                v-if="item.rowKey === editedItem?.rowKey"
                required
                :rules="requiredRules"
              ></v-text-field>
              <span v-else>{{ item.contact }}</span>
            </template>
            <template v-slot:item.workPosition="{ item }">
              <v-text-field
                class="mt-4"
                v-model="editedItem.workPosition"
                dense
                single-line
                v-if="item.rowKey === editedItem?.rowKey"
                required
                :rules="requiredRules"
              ></v-text-field>
              <span v-else>{{ item.workPosition }}</span>
            </template>
            <template v-slot:item.email="{ item }">
              <v-text-field
                class="mt-4"
                v-model="editedItem.email"
                dense
                single-line
                v-if="item.rowKey === editedItem?.rowKey"
              ></v-text-field>
              <span v-else>{{ item.email }}</span>
            </template>
            <template v-slot:item.phone="{ item }">
              <v-text-field
                class="mt-4"
                v-model="editedItem.phone"
                dense
                single-line
                v-if="item.rowKey === editedItem?.rowKey"
              ></v-text-field>
              <span v-else>{{ item.phone }}</span>
            </template>
            <template v-slot:item.phone2="{ item }">
              <v-text-field
                class="mt-4"
                v-model="editedItem.phone2"
                dense
                single-line
                v-if="item.rowKey === editedItem?.rowKey"
              ></v-text-field>
              <span v-else>{{ item.phone2 }}</span>
            </template>
            <template v-slot:item.colaboration="{ item }">
              <v-text-field
                class="mt-4"
                v-model="editedItem.colaboration"
                type="number"
                dense
                single-line
                v-if="item.rowKey === editedItem?.rowKey"
                required
                :rules="requiredRulesCollaboration"
              ></v-text-field>
              <span v-else>{{ item.colaboration }}</span>
            </template>
            <template v-slot:item.brands="{ item }">
              <v-autocomplete
                class="mt-4"
                dense
                multiple
                v-model="editedItem.brandIds"
                v-bind:placeholder="$t('start_typing_to_search')"
                item-text="name"
                item-value="id"
                :search-input.sync="search_brand"
                :loading="isBrandSearching"
                :items="filtered_brands"
                hide-no-data
                filled
                v-if="item.rowKey === editedItem?.rowKey"
                @change="onChangeSelectedBrands"
                required
                :rules="requiredRulesArray"
              >
                <template v-slot:prepend-item>
                  <v-list-item ripple @click="toggleSelectAllOptions()">
                    <v-list-item-action>
                      <v-icon
                        :color="
                          editedItem.brandIds.length > 0
                            ? 'indigo darken-4'
                            : ''
                        "
                        >{{ icon }}</v-icon
                      >
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title>Select All</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-divider class="mt-2"></v-divider>
                </template>
              </v-autocomplete>
              <span v-else>{{
                item.brands.map(el => el.name).join(", ")
              }}</span>
            </template>
            <template v-slot:item.actions="{ item }">
              <div v-if="item.rowKey === editedItem?.rowKey">
                <v-icon color="red" class="mr-3" @click="close">
                  mdi-window-close
                </v-icon>
                <v-icon color="green" @click="save">
                  mdi-content-save
                </v-icon>
              </div>
              <div v-else>
                <v-icon color="green" class="mr-3" @click="editItem(item)">
                  mdi-pencil
                </v-icon>
                <v-icon color="red" @click="deleteItem(item)">
                  mdi-delete
                </v-icon>
              </div>
            </template>
            <template v-slot:no-data>
              Empty
            </template>
          </v-data-table>
        </v-form>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import ApiService from "@/services/apiService";
import { logInfo, logError } from "@/utils";
import _ from "lodash";

export default {
  name: "contactPersonTable",
  props: ["posData", "updatePosContacts", "apiGetBrandsUrl"],
  data: function() {
    return {
      posContactData: [],
      editedIndex: -1,
      editedItem: null,
      defaultItem: {
        id: null,
        rowKey: null,
        posId: null,
        contact: null,
        workPosition: null,
        email: null,
        phone: null,
        phone2: null,
        colaboration: null,
        brands: [],
        brandIds: []
      },
      search_brand: null,
      isBrandSearching: false,
      filtered_brands: [],
      valid: true,
      requiredRules: [v => !!v || "Required"],
      requiredRulesArray: [v => (!!v && v.length > 0) || "Required"],
      requiredRulesCollaboration: [
        v => !!v || "Required",
        v =>
          !v ||
          (parseInt(v) >= 0 && parseInt(v) <= 10) ||
          "Esto debería ser de 0 a 10."
      ],
      requiredRulesPhone2: [
        v => !!v || "Required",
        v =>
          !v ||
          /^([01]\d|2[0-3]):([0-5]\d)\s*\/\s*([01]\d|2[0-3]):([0-5]\d)$/.test(
            v
          ) ||
          "Esto debería ser HH:mm / HH:mm."
      ],
      requiredRulesPhone: [
        v => !!v || "Required",
        v =>
          !v ||
          /^\d{9}$/.test(v) ||
          "El número de teléfono debe tener 9 dígitos.."
      ],
      emailRules: [
        v => !!v || "El campo de email es obligatorio",
        v =>
          !v ||
          /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
          "El email proporcionado debe ser una dirección de email válida"
      ]
    };
  },
  watch: {
    async posData(newVal, oldVal) {
      if (newVal) {
        this.init(newVal);
      }
    },
    async search_brand(val) {
      await this.search_brands(val);
    }
  },
  computed: {
    headers() {
      return [
        {
          text: `${this.$t("contact_person")}`,
          value: "contact",
          sortable: false,
          width: 150
        },
        {
          text: `${this.$t("workPosition")}`,
          value: "workPosition",
          sortable: false,
          width: 150
        },
        {
          text: `${this.$t("salesforce.email")}`,
          value: "email",
          sortable: false,
          width: 150
        },
        {
          text: `${this.$t("phone")}`,
          value: "phone",
          sortable: false,
          width: 150
        },
        {
          text: `${this.$t("call_schedule")}`,
          value: "phone2",
          sortable: false,
          width: 150
        },
        {
          text: `${this.$t("brands.brands")}`,
          value: "brands",
          sortable: false,
          width: 200
        },
        {
          text: `${this.$t("colaboration")}`,
          value: "colaboration",
          sortable: false,
          width: 150
        },
        {
          text: "Actions",
          value: "actions",
          sortable: false,
          width: 150
        }
      ];
    },
    likesAllBrands() {
      return this.editedItem.brandIds.length === this.filtered_brands.length;
    },
    icon() {
      if (this.likesAllBrands) return "mdi-close-box";
      return "mdi-checkbox-blank-outline";
    }
  },
  methods: {
    toggleSelectAllOptions() {
      if (this.likesAllBrands) {
        this.editedItem.brandIds = [];
        this.editedItem.isSelectedAllBrands = false;
      } else {
        this.editedItem.brandIds = this.filtered_brands.map(el => el.id);
        this.editedItem.brands = this.filtered_brands;
        this.editedItem.isSelectedAllBrands = true;
      }
    },
    async search_brands(val) {
      if (!val) val = "";
      let fetch_url = `${this.apiGetBrandsUrl}&filterValue=${val}`;
      const resp = await ApiService.post(fetch_url);
      this.filtered_brands = resp.data;
    },
    addNew() {
      const addObj = Object.assign({}, this.defaultItem);
      if (this.posContactData.length > 0) {
        addObj.rowKey =
          parseInt(this.posContactData[this.posContactData.length - 1].rowKey) +
          1;
      } else {
        addObj.rowKey = this.posContactData.length + 1;
      }
      if (this.posContactData.length > 0) {
        let lastItem = null;
        lastItem = this.posContactData[this.posContactData.length - 1];
        if (!lastItem?.id) {
          return;
        }
      }
      this.posContactData.push(addObj);
      this.editItem(addObj);
    },
    async editItem(item) {
      this.editedIndex = this.posContactData.indexOf(item);
      this.editedItem = item;
      await this.search_brands("");
    },
    async deleteItem(item) {
      const index = this.posContactData.indexOf(item);
      let posContactId = this.posContactData[index].id;
      if (posContactId) {
        await ApiService.delete(`pos/delete_pos_contacts/${posContactId}`);
      }
      this.posContactData.splice(index, 1);
      this.updatePosContacts(this.posContactData);
    },
    close() {
      this.editedItem = Object.assign({}, this.defaultItem);
      this.editedIndex = -1;
    },
    async save() {
      let updatedPosContactData = this.posContactData[this.editedIndex];
      if (this.$refs.form.validate()) {
        if (this.posData && this.posData?.id) {
          let postData = {
            posId: this.posData.id,
            updatedPosContactData
          };
          let { contactItem } = await ApiService.post(
            "pos/save_pos_contacts",
            postData
          );
          this.editedItem = contactItem;
          if (this.editedIndex > -1) {
            this.posContactData[this.editedIndex] = this.editedItem;
          }
          this.updatePosContacts(this.posContactData);
          this.close();
        } else {
          if (this.editedIndex > -1) {
            this.posContactData[this.editedIndex] = this.editedItem;
          }
          this.updatePosContacts(this.posContactData);
          this.close();
        }
      }
    },
    onChangeSelectedBrands() {
      if (this.editedItem) {
        let filteredBrands = this.filtered_brands.filter(el =>
          this.editedItem.brandIds.includes(el.id)
        );
        this.editedItem.brands = filteredBrands;
        if (this.likesAllBrands) {
          this.editedItem.isSelectedAllBrands = true;
        } else {
          this.editedItem.isSelectedAllBrands = false;
        }
      }
    },
    async checkSelectedAllBrands(pos_contacts) {
      try {
        let { pos_contact_data } = await ApiService.post(
          `pos/checkSelectedAllBrands`,
          {
            posContactData: pos_contacts
          }
        );
        return pos_contact_data;
      } catch (error) {
        return [];
      }
    },
    async init(posData) {
      if (posData && posData?.pos_contacts) {
        this.posContactData = await this.checkSelectedAllBrands(
          posData.pos_contacts
        );
        this.posContactData = this.posContactData.map((el, index) => {
          let pushItem = { ...el };
          pushItem.rowKey = index + 1;
          pushItem.brandIds = pushItem.brands.map(bEl => bEl.id);
          return pushItem;
        });
        this.defaultItem.posId = this.posData.id;
      } else {
        this.posContactData = [];
        this.defaultItem.posId = this.posData.id;
      }
    }
  },
  async updated() {},
  async mounted() {
    this.init(this.posData);
  }
};
</script>

<style></style>
